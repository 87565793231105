const AddressHelper = {

    isCountyNameSame(left, right) {

        const replaceMap = {
            "Saint": "St.",
            "St ": "St. ",
            "La Porte": "LaPorte"
        };

        const normalize = (name) => {
            for (const key in replaceMap) {
                name = name.replace(new RegExp(key, 'gi'), replaceMap[key]);
            }
            return name.toLowerCase().trim();
        };

        return normalize(left) === normalize(right);
    }
}

export default AddressHelper;
