
<template>
    <div class="logi-frame-container">
        <slot name="top" />
        <report-actions
            v-if="showActions"
            variant="outline-dark"
            :file-types="['PDF']"
            :get-export-content="getExportContent"
            hide-save-local
            @print="print"
        />

        <!-- report frame -->
        <div id="report1" class="logi-report" :class="{ 'logi-report-with-actions': showActions }"></div>

        <div v-if="!displayErrorMessage && showFrame">

            
        </div>
        <template v-else-if="displayErrorMessage">
            <slot name="message-display" v-bind="{ hasError, pathInvalid, mode }">
                <rq-no-data
                    :variant="hasError ? 'error' : 'default'"
                    size="lg">
                    <template #message-content>
                        <FontAwesomeIcon v-if="hasError" icon="fas fa-exclamation-circle" />
                        {{errorMessage}}
                    </template>
                </rq-no-data>
            </slot>
        </template>
        <rq-pdf-printer ref="pdfViewer" />
        <slot />
    </div>
</template>

<script>
    import { computed } from "vue";
    import { useStore } from "vuex";
    import { EXAGO_SESSION_MUTATIONS } from "@/store/mutations";
    import { ReportIframeMode, ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import ReportActions from "./ReportActions";
    import { RqPdfPrinter } from "@/shared/components/rq/";

    export default {
        name: "LogiWidgetFrame",

        components: { ReportActions, RqPdfPrinter },

        props: {
            reportPath: { type: String, default: null },
            persist: { type: Boolean, default: false },
            reportOptions: { type: Object, default: () => new ReportOptionsDto() },
            requireReportPath: { type: Boolean, default: false },
        },

        data() {
            return {
                frameUrl: "",
                frameLoaded: false,
                pathInvalid: false,
                hasError: false,
                showFrame: false,
                isBusy: true,
                actionsReady: false,
                app: {}
            };
        },

        setup() {
            const vuexStore = useStore();
            const reportAccess = computed({
                get() { return vuexStore.state.exagoSession.reportAccess; },
                set(val) { vuexStore.commit(EXAGO_SESSION_MUTATIONS.SET_REPORT_ACCESS, val); }
            });

            return {
                reportAccess
            };
        },

        watch: {
            definition: {
                handler(newValue, oldValue) {

                },
                deep: true,
                immediate: true,
            },
        },

        computed: {
            mode() {
                return !_.isNullOrEmpty(this.reportPath) || this.requireReportPath
                    ? ReportIframeMode.REPORT_VIEWER
                    : ReportIframeMode.STAND_ALONE;
            },
            isReportDesigner() { return this.mode == ReportIframeMode.STAND_ALONE; },
            displayErrorMessage() { return this.hasError || this.pathInvalid; },
            errorMessage() {
                return this.pathInvalid
                    ? "Report path invalid."
                    : this.hasError
                        ? `An issue occurred loading ${this.mode == ReportIframeMode.REPORT_VIEWER ? 'this report' : 'the Report Designer'}.`
                        : '';
            },
            definition() {
                return {
                    reportPath: this.reportPath,
                    reportOptions: this.reportOptions,
                }
            },
            showActions() { return !this.isReportDesigner && !this.isBusy && this.actionsReady; },
            pdfViewerInstance() { return _.get(this, "$refs.pdfViewer", null); },
        },

        beforeUnmount() {
            // this.endWait();
            // window.removeEventListener("message", this.onPostMessageRecieved, false);
        },

        created() {
            let bAppFactoryLoaded = this.isAppFactoryLoaded();

            if(bAppFactoryLoaded  && (!_.isNullOrEmpty(this.reportAccess))) 
                this.refresh();
        },

        methods: {
            refresh() {
                const self =  this;

                self.showFrame = false;

                self.$nextTick()
                    .then(() => {
                        self.showFrame = true;
                        self.process();
                    });
            },
            process() {
                switch(this.mode) {
                    case ReportIframeMode.STAND_ALONE:
                        break;
                    case ReportIframeMode.REPORT_VIEWER:
                        this.loadReport();
                        break;
                    default:
                        // this.clearIframe();
                        break;
                }
            },            

            loadFrame(params, reportAccess) {
                const self = this;

                // self.clearIframe();

                self.hasError = false;
                self.isBusy = true;

                if(!self.validatePath()) return;

                let entryId = "report1";
                
                let ext = (!_.isEmpty(self.reportOptions?.reportExt) ? self.reportOptions?.reportExt : ".cls");
                let reportPhysicalPath = (!_.isEmpty(self.reportOptions?.reportPhysicalPath) ? self.reportOptions?.reportPhysicalPath :  ("/" + self.reportPath + ext));                
                
                let catalogPath = _.parseBool(self.reportOptions.IsCustomReport) ? reportAccess.rsCustomCatalog : reportAccess.rsCatalog;


                // eslint-disable-next-line no-undef
                var Factory = com.jinfonet.api.AppFactory;
                let server = {
                    url: reportAccess.rsUrl, 
                    user: reportAccess.rsUser,
                    pass: reportAccess.rsPwd,
                    
                    jrd_prefer:{
                        // For page report
                        pagereport:{
                            feature_UserInfoBar:false,
                            feature_ToolBar: true,
                            feature_Toolbox: false,
                            feature_DSOTree: false,
                            feature_TOCTree: false,
                            feature_PopupMenu: false,
                            feature_ADHOC: false
                        },
                
                        // For web report
                        webreport:{
                            viewMode:{
                                hasToolbar: true,
                                hasSideArea: false
                            }
                        }
                    },
                    jrd_studio_mode: "view",
                    "jrs.param_page": false,
                    "jrs.result_type": "2"
                },
                
                prptRes = {name: reportPhysicalPath},
                catRes = {name: catalogPath};
                params = self.convertExagoToLogiParams(params, reportAccess);

                self.app = Factory.runReport(
                    server, prptRes, catRes, params, entryId);
                
                self.app.attachEvent("jreportstatus", 4, this, function(e){
                    self.onjreportstatus(e);
                });

                self.isBusy = false;
                self.actionsReady = true;
                self.$emit('loaded')
            },

            onjreportstatus(e) {
                var msg = e.getData();
                // console.log(msg);
            },

            convertExagoToLogiParams(params, reportAccess) {
                let newParams = _.cloneDeep(params);

                if(!("userId" in newParams))
                {
                    newParams.userId = reportAccess.usersId;
                }

                if(!("branchId" in newParams))
                {
                    newParams.branchId = reportAccess.branchId;
                }

                if(!("regionId" in newParams))
                {
                    newParams.regionId = reportAccess.regionId;
                }

                if("p_StartDate" in newParams && params.p_StartDate != null)
                {   
                    newParams.p_StartTime = "00:00:00";
                    newParams.p_StartDate = new Date(params.p_StartDate).toLocaleDateString('en-US');

                    newParams.p_StartDateTime = new Date(newParams.p_StartDate).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})
                        + ", 12:00:00 AM";
                    
                    if (newParams.p_StaticStartDate == null)
                    {
                        newParams.p_StaticStartDate = new Date(newParams.p_StartDate).toISOString().split('T')[0] + " 00:00:00";
                    }
                    // delete newParams["p_StartDate"];
                }

                if("p_EndDate" in newParams && params.p_EndDate != null)
                {
                    newParams.p_EndTime = "23:59:59";
                    newParams.p_EndDate = new Date(params.p_EndDate).toLocaleDateString('en-US');

                    newParams.p_EndDateTime = new Date(newParams.p_EndDate).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})
                        + ", 11:59:59 PM";

                    if (newParams.p_StaticEndDate == null)
                    {
                        newParams.p_StaticEndDate = new Date(newParams.p_EndDate).toISOString().split('T')[0] + " 23:59:59";
                    }
    
                    // delete newParams["p_EndDate"];
                }
                
                newParams.p_AppDomain = window.location.origin;

                newParams.p_utcOffset = new Date().getTimezoneOffset() * -1;
                
                return newParams;
            },

            loadReport() {
                const self = this;

                let opts = self.getReportOptions()

                self.loadFrame(opts.parameters, self.reportAccess);
            },

            getReportOptions() {
                const self = this;

                let opts = _.cloneDeep(self.reportOptions);

                if(_.isNullOrEmpty(opts.reportPath) && !_.isNullOrEmpty(self.reportPath))
                    opts.reportPath = self.reportPath;

                return opts;
            },
            validatePath() {
                const self = this;
                if(!self.requireReportPath || !_.isEmpty(self.reportPath) || (this.mode === ReportIframeMode.REPORT_VIEWER && !_.isEmpty(self.reportOptions?.reportPath))) {
                    self.pathInvalid = false;
                    return true;
                }
                self.pathInvalid = true;
                // self.endWait();
                return false;
            },
            print(data){
                let self = this;
                self.pdfViewerInstance.load(data);
            },
            async getExportContent(fileType) {
                const self = this;
                const rptSet = self.app.getReportSet();
                const opts = self.getReportOptions();
                const params = self.convertExagoToLogiParams(opts.parameters, self.reportAccess);
                const reportPath = `/${opts.reportPath + (opts.reportExt ?? '.cls')}`;
                const reportInfo = {
                    fileType,
                    fileName: rptSet.def.dname,
                    reportPath: opts.reportPhysicalPath ?? reportPath,
                    rsCatalog: _.parseBool(opts.IsCustomReport)
                        ? self.reportAccess.rsCustomCatalog
                        : self.reportAccess.rsCatalog
                };

                let exportResult = await self.$api.ReportsApi.exportReport(reportInfo, params);
                return {
                    fileName: exportResult.fileName || "",
                    description: exportResult?.name || "",
                    content: exportResult?.contentBase64 || ""
                };
            },
            isAppFactoryLoaded(){
                let bFactoryLoaded = false;
                if(typeof com !== 'undefined')
                {
                // eslint-disable-next-line no-undef
                if (com?.jinfonet?.api?.AppFactory)
                    bFactoryLoaded = true;
                }
                return (bFactoryLoaded);
            },
        }
    };
</script>
