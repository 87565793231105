/* PREMIUM PLUGINS:
    "advtable",
    "casechange",
    "checklist",
    "formatpainter",
    "linkchecker",
    "permanentpen",
    "powerpaste",
    "tinymcespellchecker",
*/
/* MOVED TO PREMIUM IN TINYMCE 6.0
    "imagetools",
    "toc",
*/
/* DEPRECATED IN TINYMCE 6.0
    "fullpage",
 */
/* ERRORING OUT IN VUE 3
    "noneditable",
    "hr",
    "print",
    "textpattern",
*/
export const BasePlugins = () => [
    "advlist",
    "anchor",
    "autolink",
    "charmap",
    "directionality",
    "emoticons",
    "fullscreen",
    "image",
    "insertdatetime",
    "link",
    "lists",
    "nonbreaking",
    "pagebreak",
    "preview",
    "quickbars",
    "searchreplace",
    "table",
    "visualblocks",
    "visualchars",
    "wordcount",
];

/* Premium Toolbar Items:
    "casechange",
    "formatpainter",
    "permanentpen"
*/
const flattenToolbarItems = groups => _.join(_.map(groups, items => _.join(items, " ")), " | ");
export const BaseToolbarList = [
    ["undo", "redo"],
    ["bold", "italic", "underline", "strikethrough"],
    ["fontfamily", "fontsize", "blocks"],
    ["alignleft", "aligncenter", "alignright", "alignjustify"],
    ["outdent", "indent"],
    ["numlist", "bullist", "checklist"],
    ["forecolor", "backcolor", "removeformat"],
    ["pagebreak"],
    // ["charmap", "emoticons"],
    ["image", "link"]
];

export const BaseToolbar = () => flattenToolbarItems(BaseToolbarList);

const QuickToolbarLists = {
    selection: [
        ["bold", "italic", "underline", "strikethrough"],//, "casechange"],
        ["forecolor", "backcolor", "removeformat"],
        ["alignleft", "aligncenter", "alignright", "alignjustify", "lineheight"],
        ["outdent", "indent"],
        ["link", "quicktable"]
    ],
    insert: [
        ["bold", "italic", "underline", "strikethrough"],
        ["link", "quicktable"]
    ]
};

export const QuickSelectionToolbar = () => flattenToolbarItems(QuickToolbarLists.selection);
export const QuickInsertToolbar = () => flattenToolbarItems(QuickToolbarLists.insert);

/* Default menu items */
// {
//     file: { title: "File", items: "preview | print " },
//     edit: { title: "Edit", items: "undo redo | cut copy paste | selectall | searchreplace" },
//     view: { title: "View", items: "visualaid visualchars visualblocks | spellchecker | preview fullscreen" },
//     insert: { title: "Insert", items: "image link inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime" },
//     format: { title: "Format", items: "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat" },
//     tools: { title: "Tools", items: "spellchecker spellcheckerlanguage | wordcount" },
//     table: { title: "Table", items: "inserttable | cell row column | tableprops deletetable" },
//     help: { title: "Help", items: "help" }
// };
export const BaseMenuConfig = () => ({
    edit: { title: "Edit", items: "undo redo | cut copy paste | selectall | searchreplace" },
    view: { title: "View", items: "spellchecker" },
    insert: { title: "Insert", items: "image link inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime" },
    format: { title: "Format", items: "bold italic underline strikethrough superscript subscript | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat" },
    tools: { title: "Tools", items: "spellchecker spellcheckerlanguage | wordcount" },
    table: { title: "Table", items: "inserttable | cell row column | tableprops deletetable" }
});

/* Global Commands */
/*
    tinymce.activeEditor.execCommand('Bold');
    tinymce.activeEditor.execCommand('Italic');
    tinymce.activeEditor.execCommand('Underline');
    tinymce.activeEditor.execCommand('Strikethrough');
    tinymce.activeEditor.execCommand('Superscript');
    tinymce.activeEditor.execCommand('Subscript');
    tinymce.activeEditor.execCommand('Cut');
    tinymce.activeEditor.execCommand('Copy');
    tinymce.activeEditor.execCommand('Paste');
    tinymce.activeEditor.execCommand('mceInsertLink', false, 'https://www.tiny.cloud');
    tinymce.activeEditor.execCommand('Unlink');
    tinymce.activeEditor.execCommand('JustifyLeft');
    tinymce.activeEditor.execCommand('JustifyCenter');
    tinymce.activeEditor.execCommand('JustifyRight');
    tinymce.activeEditor.execCommand('JustifyFull');
    tinymce.activeEditor.execCommand('JustifyNone');
    tinymce.activeEditor.execCommand('ForeColor', false, '#FF0000');
    tinymce.activeEditor.execCommand('HiliteColor', false, '#FF0000');
    tinymce.activeEditor.execCommand('FontName', false, 'courier new');
    tinymce.activeEditor.execCommand('FontSize', false, '30px');
    tinymce.activeEditor.execCommand('LineHeight', false, '1.4');
    tinymce.activeEditor.execCommand('mceApplyTextcolor', 'hilitecolor', '#FF0000');
    tinymce.activeEditor.execCommand('mceRemoveTextcolor', 'hilitecolor');
    tinymce.activeEditor.execCommand('RemoveFormat');
    tinymce.activeEditor.execCommand('mceBlockQuote');
    tinymce.activeEditor.execCommand('FormatBlock', false, 'bold');
    tinymce.activeEditor.execCommand('mceInsertContent', false, 'My new content');
    tinymce.activeEditor.execCommand('mceReplaceContent', false, 'My replacement content');
    tinymce.activeEditor.execCommand('mceSetContent', false, 'My content');
    tinymce.activeEditor.execCommand('mceToggleFormat', false, 'bold');

    tinymce.activeEditor.execCommand('ToggleSidebar');
    // OR
    tinymce.activeEditor.execCommand('ToggleSidebar', false, '<sidebar-name>');

    tinymce.activeEditor.execCommand('ToggleToolbarDrawer');
    tinymce.activeEditor.execCommand('Indent');
    tinymce.activeEditor.execCommand('Outdent');
    tinymce.activeEditor.execCommand('InsertHorizontalRule');
    tinymce.activeEditor.execCommand('InsertLineBreak');
    tinymce.activeEditor.execCommand('mceInsertNewLine');
    tinymce.activeEditor.execCommand('mceInsertRawHTML', false, '<p>Hello, World!</p>');
    tinymce.activeEditor.execCommand('mceToggleVisualAid');
    tinymce.activeEditor.execCommand('SelectAll');
    tinymce.activeEditor.execCommand('Delete');
    tinymce.activeEditor.execCommand('ForwardDelete');
    tinymce.activeEditor.execCommand('mceNewDocument');
    tinymce.activeEditor.execCommand('Redo');
    tinymce.activeEditor.execCommand('Undo');
    tinymce.activeEditor.execCommand('mceAddUndoLevel');
    tinymce.activeEditor.execCommand('mceEndUndoLevel');
    tinymce.activeEditor.execCommand('mceCleanup');
    tinymce.activeEditor.execCommand('mceSelectNode', false, '<DOM_node>');
    tinymce.activeEditor.execCommand('mceSelectNodeDepth', false, 2); // For two nodes up.

    tinymce.activeEditor.execCommand('mceRemoveNode');
    // OR
    tinymce.activeEditor.execCommand('mceRemoveNode', false, '<DOM_node>');
*/