<template>
    <RqPdfViewer
        :document="pdfContent"
        read-only
    />
</template>

<script setup>
    import { ref } from "vue";
    import { useRqBusy } from "@/shared/plugins/rqBusy";
    import { DocumentsApi } from "@/api";
    import { RqPdfViewer } from "@/shared/components/rq/";
    import { useRqToast } from "@/shared/plugins/composables";

    const props = defineProps({
        fileName: { type: String, default: "untitled.pdf" },
        contentType: { type: String, default: null },
        content: { type: String, default: null }
    });

    const { waitFor } = useRqBusy();
    const { toastError } = useRqToast();

    const pdfContent = ref(null);

    async function init() {
        if(_.isEmpty(props.content)) return;
        if(props.contentType === "application/pdf" || _.includes(props.content, "application/pdf")) {
            pdfContent.value = props.content;
            return;
        }
        await convertContentToPdf();
    }

    function createConversionFile() {
        let blobContent = props.content;
        let blobType = props.contentType;

        if(_.startsWith(blobContent, "data:")) {
            let urlBody = _.replace(props.content,/^data:\s*/, '');
            let split = _.split(urlBody, "base64,")
            blobType = split[0];
            blobContent = split[1];
        }

        if(_.isEmpty(blobType)) return null;

        let fileBlob = _.base64ToBlob(blobContent, blobType);
        return new File([fileBlob], props.fileName, { type: fileBlob.type });
    }

    async function convertContentToPdf() {
        const self = this;
        let file = createConversionFile();
        if(_.isNil(file)) return;

        let formData = new FormData();
        formData.append("docFile", file);
        try {
            let apiPromise = DocumentsApi.directConversion("pdf", formData);
            let pdfInfo = await waitFor(apiPromise);
            pdfContent.value = pdfInfo.content;
            return true;
        }
        catch(error) {
            toastError("An issue occurred while loading this document preview.");
        }
    }

    init();
</script>