import DxFilterBuilder from "../utilities/DxFilterBuilder";
import { SETTLEMENT_TYPE } from '@settlement/models';
import { UserScreenAccessLevel, AlertSeverity, LogLevel } from "./enums";

export class NewFileDialogModel {
    constructor (options) {
        options = options || {};
        this.gfNo = options.gfNo || "";
        this.workflowProcessTemplateID = options.workflowProcessTemplateID || "";
        this.regionID = _.parseNumber(options.regionID, null);
        this.branchID = _.parseNumber(options.branchID, null);
        this.titleUnitID = _.parseNumber(options.titleUnitID, null);
        this.escrowUnitID = _.parseNumber(options.escrowUnitID, null);
        this.kindOfOrder = options.kindOfOrder || 3;
        this.settlementStatementType = _.parseNumber(options.settlementStatementType, null);
    }
}

export class DuplicateOrderSearchModel {
    constructor (options) {
        options = options || {};
        this.buyerFirstName = options.buyerFirstName || "";
        this.buyerLastName = options.buyerLastName || "";
        this.buyerState = options.buyerState || "";
        this.sellerFirstName = options.sellerFirstName || "";
        this.sellerLastName = options.sellerLastName || "";
        this.sellerState = options.sellerState || "";
        this.propertyAddress = options.propertyAddress || "";
        this.propertySubdivision = options.propertySubdivision || "";
        this.propertyTaxId = options.propertyTaxId || null;
        this.allBranches = options.allBranches || false;
    }
}

export class SearchRequest {
    constructor (options) {
        options = options || {};
        this.filter = options.filter || null;
        this.searchTerm = options.searchTerm || null;
        this.parameters = options.parameters || {};
        this.parameterObjects = options.parameterObjects || {};
        this.sortBy = options.sortBy || [];
        this.skip = options.skip || 0;
        this.take = options.take || 50;
        this.pageNumber = options.pageNumber || ((this.skip/this.take) + 1);
        this.pageSize = options.pageSize || options.take;
        this.pagingEnabled = _.parseBool(options.pagingEnabled, true);
        this.customData = options.customData || null;

        /*
            TG - only use this in the situation where a particular search endpoint is used
            in multiple locations/scenarios (e.g. company search) or if a requirement calls
            for the need to dynamically derive search fields.
        */
        this.searchFields = options.searchFields || [];
    }

    get hasFilters() { return !_.isEmpty(this.filter); }

    static fromLoadOptions(loadOptions, sortExclusions=[]) {
        let result = new SearchRequest();
        result.parseLoadOptions(loadOptions, sortExclusions);
        return result;
    }

    parseLoadOptions(loadOptions, sortExclusions=[]) {
        if (!loadOptions) return;
        if(_.isNil(this.filter))
            this.filter = loadOptions.filter || null;
        if(_.isNil(this.searchTerm))
            this.searchTerm = loadOptions.searchValue || null;
        this.skip = _.parseNumber(loadOptions.skip, 0);
        this.pageNumber = this.skip + 1;
        this.take = _.parseNumber(loadOptions.take, 50);
        this.pageSize = this.take;
        if(_.isEmpty(loadOptions.sort)) return;
        _.each(loadOptions.sort, opt => {
            if(opt.selector === "clientKey" || _.includes(sortExclusions, opt.selector)) return;
            this.sortBy.push(new SortOption({
                field: opt.selector,
                direction: opt.desc ?
                    SortOption.SortDirection.Descending :
                    SortOption.SortDirection.Ascending
            }));
        });
    }

    appendFilter(expr) {
        let filterBuilder = new DxFilterBuilder(this.filter);
        filterBuilder.append(expr);
        this.filter = filterBuilder.filterExpression;
    }

    addFilter(key, opr, val) {
        this.appendFilter([key, opr, val]);
    }

    removeSorts(colNames) {
        _.remove(this.sortBy, s => _.includes(colNames, s.field));
    }
}

export class ReportAccessDto {
    constructor(options) {
        options = options || {};
        this.rsPwd = options.rsPwd || null;
        this.rsApi = options.rsApi || null;
        this.rsUrl = options.rsUrl || null;
        this.rsUser = options.rsUser || null;
        this.rsCatalog = options.rsCatalog || null;
        this.rsCustomCatalog = options.rsCustomCatalog || null;
        this.usersId = options.usersId;
        this.branchId = options.branchId;
        this.regionId = options.regionId;
        //*** Need research into passing the database connection for Logi Reports.
        // this.rsjdbcDriver = options.rsjdbcDriver;
        // this.rsjdbcUrl = options.rsjdbcUrl;
        // this.rsdbUser = options.rsdbUser;
        // this.rsdbPswd = options.rsdbPswd;
    }
}

export class AuditLogSearchRequest extends SearchRequest {
    constructor (options) {
        super(options);
        options = options || {};
        this.ordersId = _.parseNumber(options.ordersId, 0);
        this.commitmentPolicyHeaderId = _.parseNumber(options.commitmentPolicyHeaderId, 0);
    }
}
export class GridGroupItem {
    constructor (options, defaultKey) {
        options = options || {};
        this.key = options.key || defaultKey;
        this.items = options.items || [];
        this.count = _.parseNumber(options.count);
        this.summary = options.summary || [];
    }
}

export class GridRemoteResult {
    constructor (options) {
        options = options || {};
        this.data = options.data || [];
        this.groupCount = _.parseNumber(options.groupCount);
        this.totalCount = _.parseNumber(options.totalCount);
        this.summary = options.summary || [];
    }
}

// export class GridFilter {
//     constructor(options) {
//         this.field = options.field || null;
//         this.operator = options.operator || null;
//         this.value = options.value || null;
//         this.filters = _.isArray(options.filters) ? options.filters : [];
//     }

// }

export class SortOption {
    constructor (options) {
        options = options || {};
        this.field = options.field || "";
        this.direction = options.direction || "";
    }

    static get SortDirection () {
        return {
            Ascending: "ASC",
            Descending: "DESC"
        };
    }
}

export class SaleModel {
    constructor (options) {
        options = options || {};
        this.salesPrice = options.salesPrice || 0;
        this.ownerPolicyLiability = options.ownerPolicyLiability || 0;
        this.earnestMoney = options.earnestMoney || 0;
        this.downPayment = options.downPayment || 0;
        this.earnestMoneyHeldBy = (options.earnestMoneyHeldBy === 0) ? 0 : (options.earnestMoneyHeldBy || null);
        this.typeFundID = options.typeFundID || null;
    }
}

export class SelectListItem {
    constructor (options) {
        options = options || {};
        this.id = options.id || 0;
        this.index = options.index || 0;
        this.text = options.text || "";
    }
}


export class BranchDto {
	constructor(options) {
        options = options || {};

        this.branchID = _.parseNumber( options.branchID, 0);
        this.id = _.parseNumber(options.id, null) || this.branchID;
        this.batchCheckPrinter =  options.batchCheckPrinter || null;
        this.city =  options.city || null;
        this.description =  options.description || null;
        this.name =  options.name || null;
        this.newFileDept =  options.newFileDept || null;
        this.privateDocumentDirectory =  options.privateDocumentDirectory || null;
        this.reportCode =  options.reportCode || null;
        this.state =  options.state || null;
        this.zip =  options.zip || null;
        this.estimatedMonthlyPaymentOnHUD =  options.estimatedMonthlyPaymentOnHUD || null;
        this.bankCompanyID = options.bankCompanyID || null;
        this.closingPlaceContactID = options.closingPlaceContactID || null;
        this.countyID = options.countyID || null;
        this.duplicateOrderSearchSetupID = options.duplicateOrderSearchSetupID || null;
        this.fileNumberPoolID = options.fileNumberPoolID || null;
        this.lawOfficeContactID = options.lawOfficeContactID || null;
        this.newFilePersonUsersID = options.newFilePersonUsersID || null;
        this.regionID = _.parseNumber( options.regionID, null);
        this.titleCompanyCompanyID =  options.titleCompanyCompanyID || null;
        this.underwriterCompanyID = options.underwriterCompanyID || null;
        this.closingPlaceCompanyID = options.closingPlaceCompanyID || null;
        this.lawOfficeCompanyID = options.lawOfficeCompanyID || null;
        this.multipleBankAccount =  options.multipleBankAccount || null;
        this.workflowProcessTemplateID = options.workflowProcessTemplateID || null;
        this.showOnOwnerInsured =  options.showOnOwnerInsured || null;
        this.showOnMortgageBorrower =  options.showOnMortgageBorrower || null;
        this.showOnCurrentTitleHoldersBuyer =  options.showOnCurrentTitleHoldersBuyer || null;
        this.showOnCurrentTitleHoldersSeller =  options.showOnCurrentTitleHoldersSeller || null;
        this.showOnTitleVestedInBuyer =  options.showOnTitleVestedInBuyer || null;
        this.showOnTitleVestedInSeller =  options.showOnTitleVestedInSeller || null;
        this.promptForAuditLogVersion = _.parseBool(options.promptForAuditLogVersion, false)
        this.overrideOrderOptionsVestingInformationSettings =  options.overrideOrderOptionsVestingInformationSettings || null;
        this.branchLookupID = options.branchLookupID || null;
        this.checksPrinter =  options.checksPrinter || null;
        this.depositSlipPrinter =  options.depositSlipPrinter || null;
        this.invoiceTaxRate = options.invoiceTaxRate || null;
        this.settlementAgentCompanyID = options.settlementAgentCompanyID || null;
        this.settlementAgentContactID = options.settlementAgentContactID || null;
        this.defaultSellers1099 = _.parseNumber( options.defaultSellers1099, 0);
        this.premiumPayeeCompanyID = options.premiumPayeeCompanyID || null;
        this.premiumPayeeContactID = options.settlementAgentContactID || null;
        this.regionDisplay = options.regionDisplay || null;

        this.filePoolName = options.filePoolName || null;
        this.duplicateOrderSearchSetupName = options.duplicateOrderSearchSetupName || null;
        this.titleCompanyName = options.titleCompanyName || null;
        this.underwriterCompanyName = options.underwriterCompanyName || null;
        this.closingPlaceCompanyName = options.closingPlaceCompanyName || null;
        this.workflowProcessTemplateName = options.workflowProcessTemplateName || null;
        this.countyName = options.countyName || null;
        this.lawOfficeCompanyName = options.lawOfficeCompanyName || null;
        this.settlementAgentCompanyName = options.settlementAgentCompanyName || null;
        this.premiumPayeeCompanyName = options.premiumPayeeCompanyName || null;
        this.isInUse = _.parseBool( options.isInUse);
        this.documentPreview = _.parseBool(options.documentPreview, true);
        this.include0EndorsementsOnInvoice = _.parseBool(options.include0EndorsementsOnInvoice, false);
        this.standbyFeesAndTaxYear = this.isNew ? 1 : _.parseNumber(options.standbyFeesAndTaxYear, null);
    }

    get placeOfClosingCompany() {
        return {
            companyID: this.closingPlaceCompanyID,
            companyName: this.closingPlaceCompanyName
        };
    }

    set placeOfClosingCompany(val) {
        this.closingPlaceCompanyID = _.get(val, "companyID", null);
        this.closingPlaceCompanyName = _.get(val, "companyName", null);
    }

    get legalPaperworkPreparedBy() {
        return {
            companyID: this.lawOfficeCompanyID,
            companyName: this.lawOfficeCompanyName,
        };
    }

    set legalPaperworkPreparedBy(val) {
        this.lawOfficeCompanyID = _.get(val, "companyID", null);
        this.lawOfficeCompanyName = _.get(val, "companyName", null);
    }

    get settlementAgentCompany() {
        return {
            companyID: this.settlementAgentCompanyID,
            companyName: this.settlementAgentCompanyName,
        };
    }

    set settlementAgentCompany(val) {
        this.settlementAgentCompanyID = _.get(val, "companyID", null);
        this.settlementAgentCompanyName = _.get(val, "companyName", null);
    }

    get premiumPayeeCompany() {
        return {
            companyID: this.premiumPayeeCompanyID,
            companyName: this.premiumPayeeCompanyName,
        };
    }

    set premiumPayeeCompany(val) {
        this.premiumPayeeCompanyID = _.get(val, "companyID", null);
        this.premiumPayeeCompanyName = _.get(val, "companyName", null);
    }

    get isNew() { return this.branchID === 0; }

    toDataObject() { return _.toPlainObject(this); }
}

export class SystemLookupItem {
    constructor (options) {
        options = options || {};
        this.id = _.parseNumber(options.id, null);
        this.name = options.name || "";
        this.data = options.data || "";
        this.additionalIdentity = _.parseNumber(options.additionalIdentity, 0);
        this.inactive = _.parseBool(options.inactive, false);
        this.regionID = _.parseNumber(options.regionID, 0);
    }
}
export class SystemLookupItemDto extends SystemLookupItem {
    constructor (options) {
        super(options);
        options = options || {};
        //this.regionID = _.parseNumber(options.regionID, 0);
    }
}
export class LookupItem {
    constructor (options) {
        options = options || {};
        this.id = _.parseNumber(options.id, null);
        this.name = options.name || "";
        this.enabled = _.parseBool(options.enabled);
        this.data = options.data || null;
    }
}

export class RoleLookupItem extends LookupItem {
    constructor(options) {
        super(options);
        this.canAdd = _.parseBool(options.canAdd);
        this.canAssignCompany = _.parseBool(options.canAssignCompany);
        this.canDelete = _.parseBool(options.canDelete);
        this.canEdit = _.parseBool(options.canEdit);
        this.enabled = _.parseBool(options.enabled);
    }
}

export class ImageDataModel {
    constructor (options) {
        options = options || {};
        this.dataUrl = options.dataUrl || null;
        this.height = options.height || 0;
        this.width = options.width || 0;
        this.imageFormat = options.imageFormat || null;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class RoleModel {
    constructor (options) {
        options = options || {};
        this.rolesID = options.rolesID || 0;
        this.roleTypeID = options.roleTypeID || 0;
        this.ordersID = options.ordersID || 0;
        this.companyID = options.companyID || 0;
        this.companyName = options.companyName || null;
        this.contactID = options.contactID || 0;
        this.contactName = options.contactName || null;
        this.buyerSellerID = options.buyerSellerID || null;
        this.tableID = options.tableID || null;
        this.pcEnableAccess = options.pcEnableAccess || 0;
        this.pcDisplayInContacts = options.pcDisplayInContacts || 0;
        this.pcEmailEvents = options.pcEmailEvents || 0;
        this.pcEmailDocumentNotifications = options.pcEmailEvents || 0;
        this.pcEnableOrderNotes = options.pcEnableOrderNotes || 0;
        this.pcEnableWorkflow = options.pcEnableWorkflow || 1;
        this.pcEnableReceiptsDispursments = options.pcEnableReceiptsDispursments || 0;
        this.pcEnablePropertyInformation = options.pcEnablePropertyInformation || 1;
        this.pcEnableDocuments = options.pcEnableDocuments || 0;
        this.pcOverrideContact = options.pcOverrideContact || "";
        this.pcOverrideEmail = options.pcOverrideEmail || "";
        this.isBuyer = options.isBuyer || 0;
    }
}

export class UserSecuritySetting {
    constructor (options, valueType) {
        options = options || {};
        this.name = _.trim(options.name || "")
        this.description = _.trim(options.description || "");
        this.automationId = options.automationId || "";
        this.type = valueType || null;
        this.rawValue = options.value || null;
        this.value = this.parseValue(options.value, valueType);
    }

    parseValue (val, type) {
        switch (type) {
            case "int":
                return _.parseNumber(val, 0);
            case "bool":
                return _.parseBool(val);
            case "date":
                return _.parseDate(val, new Date());
        }
        return val;
    }
}

export class UserSecuritySettings {
    constructor (options) {
        options = options || {};
        const _permissions = options.permissions || [];
        this.permissions = _.map(_permissions, s => new UserSecuritySetting(s, "bool"));

        const _dates = options.dates || [];
        this.dates = _.map(_dates, s => new UserSecuritySetting(s, "date"));

        const _values = options.values || [];
        this.values = _.map(_values, s => new UserSecuritySetting(s, "int"));
    }

    get accessLevel() { return UserScreenAccessLevel; }

    get allSettings () {
        return _.concat(this.permissions, this.dates, this.values);
    }

    findValue (key, defaultValue = null) {
        const setting = _.find(this.allSettings, s => s.name === key);
        return setting ? _.defaultTo(setting.value, defaultValue) : defaultValue;
    }

    hasValue(key) {
        const setting = _.find(this.allSettings, s => s.name === key);
        return setting ? true : false;
    }

    findValues (settingNameArray) {
        const settings = {};
        _.forEach(settingNameArray, s => {settings[s] = this.findValue(s);});
        return settings;
    }

    search (searchText) {
        return _.filter(this.allSettings, s => _.includes(s.name.toLowerCase(), searchText.toLowerCase()));
    }

    getAccessLevel(keyValue, aggr="min") {
        let keys = _.parseKeyListValue(keyValue, "_ScreenAccess");
        if(_.isEmpty(keys)) return UserScreenAccessLevel.Full;
        let accessVals = this.findValues(keys);
        let accessLevel = _[aggr](_.values(accessVals));
        return accessLevel;
    }

    getMaxAccessLevel(keyValue) {
        return this.getAccessLevel(keyValue, "max");
    }

    hasAccess(keyValue) {
        return this.getAccessLevel(keyValue) !== UserScreenAccessLevel.None;
    }

    hasAnyAccess(keyValue) {
        return this.getMaxAccessLevel(keyValue) !== UserScreenAccessLevel.None;
    }

    isReadOnly(keyValue) {
        return this.getAccessLevel(keyValue) === UserScreenAccessLevel.Read;
    }

    hasPermission(keyValue) {
        let keys = _.parseKeyListValue(keyValue);
        if(_.isEmpty(keys)) return true;
        return _.every(this.findValues(keys));
    }

    hasAnyPermission(keyValue) {
        let keys = _.parseKeyListValue(keyValue);
        if(_.isEmpty(keys)) return true;
        return _.some(this.findValues(keys));
    }
}

export class ProgressItem {
    constructor (options) {
        options = options || {};
        this.key = options.key || _.uniqueId();
        this.label = options.label || options.description || `(NO LABEL) - ${this.key}`;
        this.description = options.description || options.label || `(No Description) - ${this.key}`;
        this.status = options.status || ProgressItem.status.loading;
        this.loadResult = options.loadResult || "";
    }

    static get status () {
        return {
            loading: 0,
            success: 1,
            failure: 2
        };
    }
}

export class TabItem extends ProgressItem {
    constructor (options) {
        super(options);
        options = options || {};
        this.key = options.key || _.uniqueId();
        this.value = options.value || this.label;
        this.disabled = _.parseBool(options.disabled);
        this.closeable = _.parseBool(options.disabled, true);
    }

    get progressItem () {
        return new ProgressItem(this);
    }
}

export class ModelState {
    constructor (options) {
        options = options || {};
        _.mergeWith(this, options, (thisVal, optVal) => new FieldState(optVal));
    }

    hasError (field = null) {
        if (!field) return !this.isValid;
        let fieldName = field.charAt(0).toUpperCase() + _.camelCase(field).substr(1);
        let fieldState = this[`model.${fieldName}`];
        return fieldState && !_.isEmpty(fieldState.errors);
    }

    get errors () {
        return _.flatMap(this, (val, key) => _.map(val.errors, er => er.errorMessage));
    }

    get isValid () {
        return _.every(this, (v, k) => _.isEmpty(v.errors));
    }
}

export class FieldState {
    constructor (options) {
        options = options || {};
        let fieldErrors = _.isArray(options.errors) ? options.errors : [];

        this.value = _.isUndefined(options.value) ? null : options.value;
        this.errors = _.map(fieldErrors, er => new FieldError(er));
    }
}

export class FieldError {
    constructor (options) {
        options = options || {};
        this.errorMessage = options.errorMessage || null;
        this.exception = options.exception || null;
    }
}

export class Region {
	constructor(options) {
        options = options || {};
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regID =  _.parseNumber(options.regID, null);
        this.description = options.description || null;
        this.alarmBranchFilter = _.parseBool(options.alarmBranchFilter);
        this.duplicateOrderSearchSetupID = _.parseNumber(options.duplicateOrderSearchSetupID, null);
        this.workflowProcessTemplateID = _.parseNumber(options.workflowProcessTemplateID, null);
        this.sellerPaysPercentageOwnersPremium =  _.parseNumber(options.sellerPaysPercentageOwnersPremium, 0);
        this.buyerPaysPercentageMortgagePremium =  _.parseNumber(options.buyerPaysPercentageMortgagePremium, 0);
        this.breakoutEndorsementHUD = _.parseBool(options.breakoutEndorsementHUD);
        this.defaultLinesBetweenParagraphs = _.parseNumber(options.defaultLinesBetweenParagraphs, null);
        this.oneTimeUseCompanyNotification = options.oneTimeUseCompanyNotification || null;
        this.isCombineEndorsements = _.parseBool(options.isCombineEndorsements);
        this.paperlessCloserBaseURL = options.paperlessCloserBaseURL || null;
        this.combinePrompt = _.parseBool(options.combinePrompt);
        this.defaultSSType = _.parseNumber(options.defaultSSType, null);
        this.isDefault = _.parseBool(options.isDefault);
        this.isAdminRegion = _.parseBool(options.isAdminRegion);
	}
    get displayName() { return `${this.regID} - ${this.description}`; }
}

export class CompanyPickerModel {
    constructor(options){
        options = _.isString(options) ? { companyName: options } : options || {};
        this.roleTypeID = _.parseNumber(options.roleTypeID, null);
        this.companyID = _.parseNumber(options.companyID, null);
        this.companyName = options.companyName || options.company || options.name;
        this.previousValue = options.previousValue || 0;
        this.lastPossibleEdit = new Date();
        this.contactID = _.parseNumber(options.contactID, null);
        this.contactName = options.contactName || options.contact || options.fullName;

        this.role = options.role || "";
        this.hasMore = false;
    }
    get isValid() { return _.isEmpty(this.companyName) || _.gt(this.companyID, 0); }
    get displayName() { return CompanyPickerModel.formatDisplayName(this.companyID, this.companyName); }
    matchesText(val) { return _.isEqual(_.toLower(this.companyName), _.toLower(val)) || _.isEqual(_.toLower(this.displayName), _.toLower(val)); }
    static formatDisplayName(id, name) {
        let prefix = `${id} - `;
        if(name && name.indexOf(prefix) === 0) return name;
        let nameParts = [];
        if(_.gt(_.parseNumber(id, 0), 0)) nameParts.push(id);
        if(!_.isEmpty(name)) nameParts.push(name);
        return nameParts.join(" - ");
    }
}

export class SubdivisionDto {
    constructor(options){
        options = options || {};

        this.subdivisionID = _.parseNumber(options.subdivisionID, 0);
        this.name = options.name || "";
        this.notes = options.notes || null;
        this.plat = options.plat || null;
        this.patentDate = options.patentDate || null;
        this.regionID = _.parseNumber(options.regionID, null);
        this.region = options.region || null;
        this.isDirty = _.parseBool(options.isDirty, false);
        this.canDelete = _.parseBool(options.canDelete, true);
    }
    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Subdivision Name is required.");
        }
        if (_.isNil(this.regionID) || this.regionID == 0) {
            errorList.push("Region is required.");
        }

        return errorList;
    }
}

export class SurveyDto {
    constructor(options){
        options = options || {};

        this.surveyID = _.parseNumber(options.surveyID, 0);
        this.name = options.name || "";
        this.notes = options.notes || null;
        this.patentNumber = options.patentNumber || null;
        this.patentDate = options.patentDate || null;
        this.acres = _.parseNumber(options.acres, null);
        this.countyID = _.parseNumber(options.countyID, null);
        this.abstractNumber = _.parseNumber(options.abstractNumber, null);
        this.state = options.state || "";
        this.isDirty = _.parseBool(options.isDirty, false);
        this.canDelete = _.parseBool(options.canDelete, true);
    }
    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Abstract Name is required.");
        }

        return errorList;
    }

}
export class OrderStatusModel{
    constructor(options){
        options = options || {};

        this.id = _.parseNumber(options.id, null);
        this.name = options.name || '';
        this.selected = _.parseBool(options.selected, true);
    }
}
export class OrderFacetSearchOptions{
    constructor(options){
        options = options || {};

        this.name = options.name || '';
        this.skip = _.parseNumber(options.skip, 15);
        this.take = _.parseNumber(options.take, 0);
    }
}
export class OrderFacetSearchModel{
    constructor(options){
        options = options || {};

        this.activeFacet = options.activeFacet || 'Files';
        this.useContains = _.parseBool(options.useContains, true);
        this.term = options.term || '';
        this.fileNumbers = _.map(options.fileNumbers, f => new FileNumberItemModel(f)) || [];
        this.fileNumbersTotalCount = _.parseNumber(options.fileNumbersTotalCount, 0);
        this.hasMoreFileNumbers = (this.fileNumbers.length < this.fileNumbersTotalCount);
        this.facetTotalCount = _.parseNumber(options.facetTotalCount, 0);
        this.facetCounts = options.facetCounts || [];

        this.exactMatch = new FileNumberItemModel({ ordersId: 0, fileNumber: "None", matchType: "exact" });
        this.containsItems = [];
        this.similarItems = [];

        //parsing instead of filtering to ensure result sequence is maintained
        _.forEach(this.fileNumbers, item => {
            let fileParts = _.split(item.fileNumber, " (");
            let itemText = fileParts.length > 0 ? _.toLower(fileParts[0]) : "";
            if(itemText === _.toLower(this.term)) {
                item.matchType = "exact";
                this.exactMatch = item;
            }
            else if(_.includes(itemText, _.toLower(this.term))){
                item.matchType = "contains";
                this.containsItems.push(item);
            }
            else{
                item.matchType = "similar";
                this.similarItems.push(item);
            }
        });
    }
}
export class OrderFacetDataSearchModel{
    constructor(options){
        options = options || {};

        this.activeFacet = options.activeFacet || 'Files';
        this.term = options.term || '';
        this.useContains = _.parseBool(options.useContains, true);
        this.includeFacetCounts = _.parseBool(options.includeFacetCounts, true);
        this.facetTotalCount = _.parseNumber(options.facetTotalCount, 0);
        this.facetCounts = options.facetCounts || [];
    }
}
export class OrderFacetBase {
    constructor(options){
        options = options || {};

        this.ordersID = _.parseNumber(options.ordersID, null);
        this.fileNumber = options.fileNumber || null;
        this.status = options.status || null;
        this.regionDisplay = options.regionDisplay || null;
        this.branchDisplay = options.branchDisplay || null;
        this.hasAccess = _.parseBool(options.hasAccess);
    }
}
export class OrdersFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.otherCompaniesFileNumber = options.otherCompaniesFileNumber || null;
        this.salesPrice = _.parseNumber(options.salesPrice, 0);
        this.closeDate = _.isNil(options.closeDate) ? '' : options.closeDate;
        this.fundDate = _.isNil(options.fundDate) ? '' : options.fundDate;
        this.closingAgent = options.closingAgent || null;
        this.underwriter = options.underwriter || null;
    }
}

export class PropertiesFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.address = options.address || null;
        this.county = options.county || null;
        this.subdivision = options.subdivision || null;
    }
}
export class LoansFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.loanNumber = options.loanNumber || null;
        this.amount = _.parseNumber(options.amount, null);
        this.lender = options.lender || null;
    }
}
export class PoliciesFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.commitmentNumber = options.commitmentNumber || null;
        this.ownerPolicyNumber = options.ownerPolicyNumber || null;
        this.mortgagePolicyNumber = options.mortgagePolicyNumber || null;
        this.underwriter = options.underwriter || null;
    }
}

export class BuyersSellersFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.role = options.role || null;
        this.name1 = options.name1 || null;
        this.name2 = options.name2 || null;
        this.formalName = options.formalName || null;
        this.businessName = options.businessName || null;
        this.emailAddress1 = options.emailAddress1 || null;
        this.emailAddress2 = options.emailAddress2 || null;
    }
}

export class ContactsFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.role = options.role || null;
        this.firstName = options.firstName || null;
        this.lastName = options.lastName || null;
        this.fullName = options.fullName || null;
        this.friendlyName = options.friendlyName || null;
        this.emailAddress = options.emailAddress || null;
    }
}
export class FileNumberItemModel{
    constructor(options){
        options = options || {};
        this.ordersId = _.parseNumber(options.ordersId, 0);
        this.fileNumber = options.fileNumber || null;
        this.matchType = options.matchType || null;
    }

    get isExact() { return this.matchType === "exact"; }
    get isContains() { return this.matchType === "contains"; }
    get isSimilar() { return this.matchType === "similar"; }
    get isValid() { return this.ordersId > 0; }
}
export class OrderHeaderModel{
    constructor(options){

        this.ordersId = options.ordersId || 0;
		this.gfno = options.gfno || null;
        this.status = options.status || null;
        this.hasMore = false;
    }
}
export class OrderSettingsViewModel {
    constructor (options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, null);
        this.fileNumber = options.fileNumber || null;
        this.isGlobal = _.parseBool(options.isGlobal);
        this.includeAlta = _.parseBool(options.includeAlta);
        this.isEscrowLocked = _.parseBool(options.isEscrowLocked);
        this.isLocked = _.parseBool(options.isLocked);
        this.isWithOutSeller = _.parseBool(options.isWithOutSeller);
        this.regionID = _.parseNumber(options.regionID, null);
        this.branchID = _.parseNumber(options.branchID) || null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.cashToClosePopulateFrom = _.parseNumber(options.cashToClosePopulateFrom, 1);
        this.settlementStatementType = _.parseNumber(options.settlementStatementType, SETTLEMENT_TYPE.CDF);
        this.isSettlementLocked = _.parseBool(options.isSettlementLocked);
        this.watermarkStatus = options.watermarkStatus || null;
        this.defaultLoanID = _.parseNumber(options.defaultLoanID, null);
        this.settlementTemplateID = _.parseNumber(options.settlementTemplateID, null);
        this.settlementTemplateFile = options.settlementTemplateFile || null;
        this.hasSettlementChanges = _.parseBool(options.hasSettlementChanges);
        this.useTemplatePage2A2B = _.parseBool(options.useTemplatePage2A2B);
        this.isReverseMortgage = _.parseBool(options.isReverseMortgage, false);
     }
}

export class ReorderRequestChange {
    constructor(options){
        options = options || {};
        this.id = _.parseNumber(options.id, 0);
        this.orderId = _.parseNumber(options.orderId, 0);
        this.description = options.description || "";

        let singleOrdinal = _.parseNumber(options.ordinal, 0);
        this.fromOrdinal = _.parseNumber(options.fromOrdinal, 0) || singleOrdinal;
        this.toOrdinal = _.parseNumber(options.toOrdinal, 0) || singleOrdinal;
    }

    get ordinal() { return this.toOrdinal; }
    set ordinal(val) { this.toOrdinal = val; }
}

export class ListBoxItemModel {
    constructor(options) {
        options = options || {};
        this.itemID = _.parseNumber(options.id || options.itemID);
        if(isNaN(this.itemID)) this.itemID = _.parseInt(_.uniqueId()) * -1;
        this.itemName = options.name || options.itemName || "";
        this.isInactive = _.parseBool(options.isInactive);
        this.isError = _.parseBool(options.isError, false);
        this.isSelected = _.parseBool(options.isSelected);
        this.isMarked = _.parseBool(options.isMarked);
        this.sequence = _.parseNumber(options.sequence, 0);
        this.parentIndex = _.parseNumber(options.parentIndex, 0);
        this.data = options.data || null;
    }
}

export class RqBannerAlert {
    constructor(options) {
        options = options || {};
        this.icon = options.icon || "";
        this.title = options.title || "";
        this.message = options.message || "";
        this.variant = options.variant || "default";
        this.dismissable = _.parseBool(options.dismissable);
        this.visible = _.parseBool(options.visible, true);
        this.sticky = _.parseBool(options.sticky);
    }
}

export class RqAction {
    constructor(options) {
        const opts = options || {};
        this.key = opts.key || _.uniqueId("rq-action-");
        this.automation_id = opts.automation_id || `${this.key}_automation_id`;
        this.id = opts.id || opts.automation_id || this.key;
        this.name = opts.name || "";
        this.icon = opts.icon || "";
        this.text = opts.text || "";
        this.tooltip = opts.tooltip || "";
        this.eventName = opts.eventName || "";
        this.permissionKeys = opts.permissionKeys || [];
        this.accessKey = opts.accessKey || "";
        this.shortcutKeys = opts.shortcutKeys || [];
        this.observeAccessGlobally = _.parseBool(opts.observeAccessGlobally);
        this.disableWhenReadOnly = _.parseBool(opts.disableWhenReadOnly);
        this._children = opts.children || [];
    }

    get children() { return _.map(this._children, c => new RqAction(c)); }
}

export class StandardLanguageDto {
    constructor(options) {
        options = options || {};
        this.standardLanguageID = options.standardLanguageID || options.id || null;
        this.standardLanguageCategoryID = _.parseNumber(options.standardLanguageCategoryID, 0);
        this.standardLanguagePackageID = _.parseNumber(options.standardLanguagePackageID, 0);
        this.standardLanguagePackageDetailID = _.parseNumber(options.standardLanguagePackageDetailID, null);
        this.regionID = _.parseNumber(options.regionID, null);
        this.workflowTaskID =  _.parseNumber(options.workflowTaskID, null);
        this.workflowTaskName =  options.workflowTaskName || null;
        this.isManualLanguage = _.parseBool(options.isManualLanguage, null);

        this.code = options.code || null;
        this.description = options.description || null;
        this.notes = options.notes || null;

        this.appliesToType = _.parseNumber(options.appliesToType, null);
        this.overrideOption = _.parseNumber(options.overrideOption, null);
        this.linesBetweenClauses = _.parseNumber(options.linesBetweenClauses, 1);

        this.parentID = _.parseNumber(options.parentID, null);
        this.ordinal = _.parseNumber(options.ordinal, null);
        this.level = _.parseNumber(options.level, null);
        this.sequence = _.parseNumber(options.sequence, null);
        this.bulletCharacter = options.bulletCharacter || null;
        this.bulletSize = _.parseNumber(options.bulletSize, 0);
        this.charBeforeNumber = options.charBeforeNumber || null;
        this.charAfterNumber = options.charAfterNumber || null;
        this.firstNumber = _.parseNumber(options.firstNumber, null);
        this.formatCharacter = options.formatCharacter || null;
        this.hangingIndent = _.parseNumber(options.hangingIndent, null);
        this.leftIndent = _.parseNumber(options.leftIndent, null);
        this.numberFormat = options.numberFormat || null;
        this.restartNumbering = _.parseBool(options.restartNumbering);
        this.textAfterNumber = options.textAfterNumber || null;
        this.textBeforeNumber = options.textBeforeNumber || null;
        this.textControlListType = options.textControlListType || null;
        this.overrideListFormat = _.parseBool(options.overrideListFormat);
        this.overrideLinesBetweenClauses = _.parseBool(options.overrideLinesBetweenClauses);
        this.linesBetweenClauses = _.parseNumber(options.linesBetweenClauses, 0);

        this.text = options.text || options.rtfText || null;
        this.html = options.html || options.htmlText || null;
    }

    toDataObject() { return _.toPlainObject(this); }
}

const listFormatDefaults = {
    overrideListFormat: false,
    bulletCharacter: String.fromCharCode(183),
    bulletSize: 0,
    charBeforeNumber: String.fromCharCode(0),
    charAfterNumber: ".",
    firstNumber: 1,
    formatCharacter: 9,
    hangingIndent: 360,
    leftIndent: 0,
    numberFormat: 3,
    restartNumbering: false,
    textAfterNumber: ".",
    textBeforeNumber: String.fromCharCode(0),
    textControlListType: 1
};

export class DocumentListFormat{
    constructor(options, nullDefaults=false) {
        options = options || {};

        let formatDefaults = nullDefaults
            ? DocumentListFormat.nullDefaults
            : DocumentListFormat.defaults;

        this.overrideListFormat = _.parseBool(options.overrideListFormat, formatDefaults.overrideListFormat);
        this.bulletCharacter = options.bulletCharacter || formatDefaults.bulletCharacter;
        this.bulletSize = options.bulletSize || formatDefaults.bulletSize;
        this.charBeforeNumber = options.charBeforeNumber || formatDefaults.charBeforeNumber;
        this.charAfterNumber = options.charAfterNumber || formatDefaults.charAfterNumber;
        this.firstNumber = options.firstNumber || formatDefaults.firstNumber;
        this.formatCharacter = options.formatCharacter || formatDefaults.formatCharacter;
        this.hangingIndent = options.hangingIndent || formatDefaults.hangingIndent;
        this.leftIndent = options.leftIndent || formatDefaults.leftIndent;
        this.numberFormat = options.numberFormat || formatDefaults.numberFormat;
        this.restartNumbering = _.parseBool(options.restartNumbering, formatDefaults.restartNumbering);
        this.textAfterNumber = options.textAfterNumber || formatDefaults.textAfterNumber;
        this.textBeforeNumber = options.textBeforeNumber || formatDefaults.textBeforeNumber;
        this.textControlListType = options.textControlListType || formatDefaults.textControlListType;
        this.level = options.level || 1;
    }

    defaultOverridden() {
        return _.some(listFormatDefaults, (v,k) => {
            return k !== "overrideListFormat" && this[k] !== v;
        });
    }

    static get defaults(){ return _.clone(listFormatDefaults); }
    static get nullDefaults() { return _.mapValues(listFormatDefaults, v => null); }

    toDataObject() { return _.clone(this); }
}





export class BaseEnumeration {

    constructor(lookupTypes, valueExpr, displayExpr, sortKey) {
        const self = this;

        _.forEach(lookupTypes, (value, key) => {
            self[key] = value[valueExpr];
        });

        self._definition = {
            valueExpr: valueExpr,
            displayExpr: displayExpr,
            sortKey: sortKey || '_displayValue',
        }

        let mappedValues = _.mapValues(_.cloneDeep(lookupTypes), (value, enumKey) => {
            return _.merge(value, {
                _displayValue: _.get(value, self._definition.displayExpr)
                    || _.startCase(_.camelCase(enumKey))
            });
        });

        let orderedValues = _.orderBy(mappedValues, [self._definition.sortKey], ['asc']);

        self.values = Object.freeze(orderedValues);
    }

    get lookupItems() {
        return _.map(this.values, (value) => {
            let idValue = value[this._definition.valueExpr]
            return {
                id: idValue,
                name: value._displayValue,
            };
        });
    }

    getObjects(filter) {
        return _.filter(this.values, filter);
    }

    getObject(filter) {
        return _.head(this.getObjects(filter));
    }

    getObjectByKey(val) {
        return this.getObject({ [this._definition.valueExpr]: val })
    }

    getFieldByKey(val, fieldName) {
        return _.get(this.getObjectByKey(val), fieldName);
    }

    getFieldByFilter(filter, fieldName) {
        return _.map(this.getObjects(filter), (item) => ({
            [this._definition.valueExpr]: item[this._definition.valueExpr],
            [fieldName]: item[fieldName],
        }));
    }

    // Generally common.
    displayValue(val) {
        return this.getFieldByKey(val, '_displayValue');
    }
}
export class TabAlert {
    constructor(options) {
        options = options || {};
        this.alertCount = _.parseNumber(options.alertCount, 0);
        this.alertType = _.parseNumber(options.alertType, AlertSeverity.Error);
        this.alertMessages = options.alertMessages || [];
        this.customDataTabId = _.parseNumber(options.customDataTabId, 0);
    }
    get hasAlerts() { return this.alertCount > 0; }
    get tabClass() {
        return _.evalCssObject({
            "rq-tab": true,
            "tab-alert": this.hasAlerts,
            "tab-alert-warning": this.hasAlerts && this.alertType === AlertSeverity.Warning,

            //this can be made explicit again (e.g. this.alertType === AlertSeverity.Error) if other types are needed.
            //for now, it needs to default to something in case someone doesn't set a value properly
            "tab-alert-error": this.hasAlerts && this.alertType !== AlertSeverity.Warning
        });
    }
    static combine(...alerts) {
        let result = new TabAlert();
        result.alertType = AlertSeverity.Info;
        _.each(alerts, alert => {
            result.alertCount += alert.alertCount;
            if(alert.alertType > result.alertType) result.alertType = alert.alertType;
            result.alertMessages.push(...alert.alertMessages);
        });
        return result;
    }
    toDataObject() {
        let result = _.toPlainObject(this);
        result.tabClass = this.tabClass;
        return result;
    }
}

const wrapRtfValue = (text="") => `{\\rtf1\\deff0{\\fonttbl{\\f0 Calibri;}}{\\colortbl ;\\red0\\green0\\blue255 ;}{\\*\\defchp \\fs22}{\\stylesheet {\\ql\\fs22 Normal;}{\\*\\cs1\\fs22 Default Paragraph Font;}{\\*\\cs2\\ul\\fs22\\cf1 Hyperlink;}{\\*\\ts3\\tsrowd\\fs22\\ql\\tsvertalt\\cltxlrtb Normal Table;}}{\\*\\listoverridetable}{\\info}\\nouicompat\\splytwnine\\htmautsp\\expshrtn\\spltpgpar\\deftab720\\sectd\\marglsxn1440\\margrsxn1440\\margtsxn1440\\margbsxn1440\\headery720\\footery720\\pgwsxn12240\\pghsxn15840\\cols1\\colsx720\\pard\\plain\\ql{\\fs22\\cf0 ${text}}\\fs22\\cf0\\par}`;
export const WrapRtf = (text="", encoded=false) => {
    let result = wrapRtfValue(text);
    return encoded
        ? btoa(result)
        : result;
};

export const WrapHtml = (text="") => `<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="application/xhtml+xml; charset=utf-8"><title></title><style type="text/css">body{ font-family:'Times New Roman'; font-size:1em; }
ul, ol{ margin-top: 0; margin-bottom: 0; }
.Normal{text-align:left;page-break-inside:auto;page-break-after:auto;page-break-before:avoid;line-height:normal;margin-top:0pt;margin-bottom:0pt;margin-left:0pt;text-indent:0pt;margin-right:0pt;font-family:Times New Roman;font-size:12pt;text-transform:none;font-weight:normal;font-style:normal;font-variant:normal;text-decoration: none;}
.Normal__Web_{text-align:left;page-break-inside:auto;page-break-after:auto;page-break-before:avoid;line-height:normal;-sf-before-space-auto:yes;margin-top:14pt;-sf-after-space-auto:yes;margin-bottom:14pt;margin-left:0pt;text-indent:0pt;margin-right:0pt;font-family:Times New Roman;font-size:12pt;text-transform:none;font-weight:normal;font-style:normal;font-variant:normal;text-decoration: none;}
.Default_Paragraph_Font{font-family:Calibri;font-size:10pt;text-transform:none;font-weight:normal;font-style:normal;font-variant:normal;text-decoration: none;}
.Hyperlink{color:#0000FF;font-family:Calibri;font-size:10pt;text-transform:none;font-weight:normal;font-style:normal;font-variant:normal;text-decoration: underline;}
.Normal_Table{}
.Table_Grid{}
</style></head><body><div class="Section0"><p class="Normal__Web_" style="margin-top:0pt;margin-bottom:0pt;border-top-style:hidden;border-left-style:hidden;border-right-style:hidden;border-bottom-style:hidden;text-decoration: none"><span lang="en-US" style="font-family:Tahoma;font-size:11pt;text-transform:none;font-weight:normal;font-style:normal;font-variant:normal;text-decoration: none;">${text}</span></p>
</div></body></html>`;

export class LicenseUserDetail {
    constructor(options={}) {
        this.isLicenseEnforced = _.parseBool(options?.isLicenseEnforced);
        this.userCount = _.parseInt(options?.userCount, 0);
        this.userLimit = _.parseInt(options?.userLimit, 0);
        this.alertThreshold = _.parseNumber(options?.alertThreshold, 95);
    }

    get showAlert() {
        return this.isLicenseEnforced
            && this.userLimit > 0
            && ((this.userCount / this.userLimit) * 100) > this.alertThreshold;
    }
}

export class ClientLogInfo {
    constructor(options={}) {
        this.tenantName = options.tenantName || "unavailable";
        this.enterpriseTenantId = options.enterpriseTenantId || "unavailable";
        this.userId = _.parseNumber(options.userId, 0);
        this.userName = options.userName || "unavailable";
        this.publisherId = options.publisherId || "unavailable";
        this.level = _.parseNumber(options.level, LogLevel.Info);
        this.message = options.message || null;
        this.errorMessage = options.errorMessage || null;
    }
}