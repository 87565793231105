import { createStore } from "vuex";
import * as getters from "./getters";
import { SET_ERROR, SET_PAGE, SET_PAGE_ACCESS_LEVEL, SET_PAGE_READ_ONLY,
    SET_SAVED_ROUTE, SET_FILE_IN_USE_PROMPT, SET_ROUTE_LOOKUPS_REFRESHED, SET_ROUTE_SS_MAPPED,
    SET_BUSY_QUEUE, SET_BUSY_MESSAGE } from "./mutations";
import modules from "./modules";

import { UserScreenAccessLevel } from '../shared/models/enums';
import { START_WAIT, END_WAIT, END_ALL_WAITS, LOG_SERVER_EVENT } from "./actions";
//import { UserSecuritySettings } from '../shared/models/models';
import { useLicenseStore } from "./modules/license";
import EnvironmentApi from "@/api/environment";

const debug = process.env.NODE_ENV !== "production";
const getDefaultBusyMessage = () => ({
    key: null,
    message: ""
});
const getDefaultState = () => ({
    error: null,
    lastPage: null,
    isPageReadOnly: false,
    fileInUsePrompt: true,
    pageAccessLevel: null,
    savedRoute: null,
    routeLookupsRefreshed: null,
    routeSSMapped: null,
    isSupportedDevice: null,
    busyQueue: [],
    busyMessage: getDefaultBusyMessage()
});

const store = createStore({
    state: getDefaultState(),
    mutations: {
        [SET_ERROR] (state, value) { state.error = value; },
        [SET_PAGE] (state, value) { state.lastPage = value; },
        [SET_PAGE_ACCESS_LEVEL] (state, value) { state.pageAccessLevel = value; },
        [SET_FILE_IN_USE_PROMPT] (state, value) {state.fileInUsePrompt = value; },
        [SET_PAGE_READ_ONLY] (state, value) { state.isPageReadOnly = value; },
        [SET_SAVED_ROUTE] (state, value) { state.savedRoute = value; },
        [SET_ROUTE_LOOKUPS_REFRESHED] (state, value) { state.routeLookupsRefreshed = value; },
        [SET_ROUTE_SS_MAPPED] (state, value) { state.routeSSMapped = value; },
        [SET_BUSY_QUEUE] (state, value=[]) { state.busyQueue = value; },
        [SET_BUSY_MESSAGE] (state, value=null) {
            let messageObj = getDefaultBusyMessage();
            if(_.isString(value))
                messageObj.message = value;
            else if(_.isObject(value))
                messageObj = { key: null, message: null, ...value };
            state.busyMessage = messageObj;
        }
    },
    getters: {
        ...getters,
        isBusy: state => !_.isEmpty(state.busyQueue),
        busyMessage: state => {
            let { key, message } = state?.busyMessage || null;
            if(_.isEmpty(key) || _.isEmpty(message) || !_.includes(state.busyQueue, key)) return "";
            return message;
        }
    },
    actions: {
        [SET_PAGE] (context, route) {
            let featureFlag = route?.meta?.featureFlag;
            if(!_.isEmpty(featureFlag)) {
                const licenseStore = useLicenseStore();
                if(!licenseStore.checkFeature(featureFlag)) {
                    return UserScreenAccessLevel.None;
                }
            }

            if(_.getBool(route, "meta.bypassPermissionCheck")) {
                context.commit(SET_PAGE_ACCESS_LEVEL, { key:"Custom", value: -1 });
                return;
            }

            let routeAccess = context.getters.getRouteAccess(route);

            if(!routeAccess.hasAccess) return routeAccess.accessLevel;
            //RQO-13762 - moved all this to App.vue
            // let isFileInUse = _.getBool(context.state, "orders.orderSummary.isFileInUse");
            // let ignoreFileInUse = _.getBool(context.state, "orders.orderSummary.ignoreFileInUse");
            // if (!isFileInUse && !ignoreFileInUse) {
            //     context.commit(SET_PAGE_READ_ONLY, routeAccess.accessLevel !== UserScreenAccessLevel.Full);
            // }
            const isConsolidatedFile = _.getBool(context.getters, "orderSummary.isConsolidatedFile");
            context.commit(SET_PAGE_READ_ONLY, routeAccess.accessLevel !== UserScreenAccessLevel.Full || isConsolidatedFile);
            context.commit(SET_PAGE_ACCESS_LEVEL, { key: _.join(routeAccess.keys, ","), value: routeAccess.accessLevel });
            return routeAccess.accessLevel;
        },
        [START_WAIT]({ commit, state }, key) {
            let queue = _.clone(state?.busyQueue);
            if(_.includes(queue, key)) return;
            queue.push(key);
            return commit(SET_BUSY_QUEUE, queue);
        },
        [END_WAIT]({ commit, state }, key) {
            let queue = _.clone(state?.busyQueue);
            if(!_.includes(queue, key)) return;
            _.pull(queue, key);
            // if(!_.isNil(key) && state?.busyMessage?.key === key)
            //     commit(SET_BUSY_MESSAGE);
            return commit(SET_BUSY_QUEUE, queue);
        },
        [END_ALL_WAITS]({ commit, state }) {
            if(_.isEmpty(state?.busyQueue)) return;
            return commit(SET_BUSY_QUEUE);
        },
        async [LOG_SERVER_EVENT](context, logInfo) {
            try {
                const licenseStore = useLicenseStore();
                if(!licenseStore.checkFeature("clientSessionLogging")) return;
                await EnvironmentApi.logClientEvent(logInfo);
            }
            catch(err) {
                console.err("An error while submitting the last log event to the server.", err);
            }
        }
    },
    modules,
    strict: debug
});

export default store;