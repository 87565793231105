import { SPACER, READY2CLOSE, PUBLISH, ADD_FILE_GROUP } from "./global";
export const Actions = {
    SEARCH_DOCS: {
        automation_id: 'btn_search_docs',
        key: "file-scan:search",
        text: "Search Docs",
        icon: "fa-search",
        tooltip: "Search All Documents"
    },
    MANAGE_TAGS: {
        automation_id: 'btn_manage_tags',
        key: "file-scan:manage-tags",
        text: "Manage Tags",
        icon: "fa-search",
        tooltip: "Manage Document Tags"
    },
    READY2CLOSE,
    PUBLISH
    // DEFAULT_CATEGORY: {
    //     automation_id: 'btn_set_default_category',
    //     key: "file-scan:set-default-category",
    //     text: "Default Category",
    //     icon: "fa-search",
    //     tooltip: "Set Users Document Mannagement Default Category"
    // }
};

export const ViewActions = {
    MAIN: [
        SPACER, SPACER, ADD_FILE_GROUP,
        SPACER, SPACER, READY2CLOSE, PUBLISH,
    ],
    FILE_SCAN: [
        SPACER, SPACER, Actions.SEARCH_DOCS,
        SPACER, SPACER, Actions.MANAGE_TAGS
    ]
};