import { RECORDING_ACTIONS } from '../actions';
import { RECORDING_MUTATIONS } from '../mutations';
import { HttpWrapper } from '../../shared/services';
import RecordingFeesApi from '../../api/recordingfees';
import TransferTaxesApi from '../../api/transfertaxes';
import RecordingInfoApi from '../../api/recordingInformation';

import { NOT_IMPLEMENTED, BASIC_ACTION, ACTION_WITH_COMMIT } from "../helpers";

const state = {
    selectedLoanId: 0,
    loans: [],
    properties: [],
    recordingFeeItems: [],
    transferTaxItems: [],
    recordingInformationItems: [],
    lookups: {},
    totalAmountValue: 0
};

const getters = { };

const actions = {

    [RECORDING_ACTIONS.GET_LOANS_AND_PROPERTIES] (context, orderId) {
        return ACTION_WITH_COMMIT(context, RecordingFeesApi.getLoansAndProperties(orderId), RECORDING_MUTATIONS.SET_LOANS_AND_PROPERTIES);
    },

    [RECORDING_ACTIONS.GET_LOOKUPS] (context, orderId) {
        return ACTION_WITH_COMMIT(context, RecordingFeesApi.getLookups(orderId), RECORDING_MUTATIONS.SET_LOOKUPS);
    },

    [RECORDING_ACTIONS.GET_RECORDING_INFORMATION] (context, orderId) {
        return ACTION_WITH_COMMIT(context, RecordingInfoApi.getList(orderId), RECORDING_MUTATIONS.SET_RECORDING_INFORMATION);
    },

    [RECORDING_ACTIONS.ADD_RECORDING_INFORMATION] (context, data) {
        return ACTION_WITH_COMMIT(context, RecordingInfoApi.add(data), RECORDING_MUTATIONS.ADD_RECORDING_INFORMATION);
    },

    [RECORDING_ACTIONS.UPDATE_RECORDING_INFORMATION] (context, saveRequest) {
        return BASIC_ACTION(context, RecordingInfoApi.update(saveRequest), ()=>{
            context.commit(RECORDING_MUTATIONS.UPDATE_RECORDING_INFORMATION, saveRequest.data);
        });
    },

    [RECORDING_ACTIONS.DELETE_RECORDING_INFORMATION] (context, id) {
        return BASIC_ACTION(context, RecordingInfoApi.delete(id), ()=>{
            context.commit(RECORDING_MUTATIONS.REMOVE_RECORDING_INFORMATION, id);
        });
    },

    [RECORDING_ACTIONS.GET_RECORDING_FEE_CALCULATIONS] (context, loanId) {
        return ACTION_WITH_COMMIT(context, RecordingFeesApi.getList(context.state.selectedLoanId), RECORDING_MUTATIONS.SET_RECORDING_FEE_CALCULATIONS);
    },

    [RECORDING_ACTIONS.PULL_RECORDING_FEE_CALCULATIONS] (context) {
        return BASIC_ACTION(context, RecordingFeesApi.pullList(context.state.selectedLoanId), ()=>{
            context.dispatch(RECORDING_ACTIONS.GET_RECORDING_FEE_CALCULATIONS);
        });
    },

    [RECORDING_ACTIONS.ADD_RECORDING_FEE_CALCULATION] (context, data) {
        return ACTION_WITH_COMMIT(context, RecordingFeesApi.add(data), RECORDING_MUTATIONS.ADD_RECORDING_FEE_CALCULATION);
    },

    [RECORDING_ACTIONS.UPDATE_RECORDING_FEE_CALCULATION] (context, saveRequest) {
        return ACTION_WITH_COMMIT(context, RecordingFeesApi.update(saveRequest), RECORDING_MUTATIONS.UPDATE_RECORDING_FEE_CALCULATION);
    },

    [RECORDING_ACTIONS.DELETE_RECORDING_FEE_CALCULATION] (context, id) {
        return BASIC_ACTION(context, RecordingFeesApi.delete(id), ()=>{
            context.commit(RECORDING_MUTATIONS.REMOVE_RECORDING_FEE_CALCULATION, id);
        });
    },

    [RECORDING_ACTIONS.GET_TRANSFER_TAX_CALCULATIONS] (context) {
        return ACTION_WITH_COMMIT(context, TransferTaxesApi.getList(context.state.selectedLoanId), RECORDING_MUTATIONS.SET_TRANSFER_TAX_CALCULATIONS);
    },

    [RECORDING_ACTIONS.PULL_TRANSFER_TAX_CALCULATIONS] (context) {
        return BASIC_ACTION(context, TransferTaxesApi.pullList(context.state.selectedLoanId), (result)=>{
            context.dispatch(RECORDING_ACTIONS.GET_TRANSFER_TAX_CALCULATIONS);
        });
    },

    [RECORDING_ACTIONS.ADD_TRANSFER_TAX_CALCULATION] (context, data) {
        return ACTION_WITH_COMMIT(context, TransferTaxesApi.add(data), RECORDING_MUTATIONS.ADD_TRANSFER_TAX_CALCULATION);
    },

    [RECORDING_ACTIONS.UPDATE_TRANSFER_TAX_CALCULATION] (context, saveRequest) {
        return ACTION_WITH_COMMIT(context, TransferTaxesApi.update(saveRequest), RECORDING_MUTATIONS.UPDATE_TRANSFER_TAX_CALCULATION);
    },

    [RECORDING_ACTIONS.DELETE_TRANSFER_TAX_CALCULATION] (context, id) {
        return BASIC_ACTION(context, TransferTaxesApi.delete(id), ()=>{
            context.commit(RECORDING_MUTATIONS.REMOVE_TRANSFER_TAX_CALCULATION, id);
        });
    },
};

const mutations = {
    [RECORDING_MUTATIONS.SET_LOANS_AND_PROPERTIES] (state, value) {
        let loans =_.chain(value).groupBy("loanId").map(function(v, i) {
            return {
                loanId: Number(i),
                loanOrder: _.parseNumber(v[0].loanOrder,null),
                loanNumber: _.get(_.find(v, 'loanNumber'), 'loanNumber'),
                lender: _.get(_.find(v, 'lender'), 'lender'),
                properties: _.map(v, function(p){ return { address1: p.address1, city: p.city, state: `${p.state} ${p.zip}`.trimRight() };})
            };}).value();
        loans = _.sortBy(loans, 'loanOrder');
        state.loans = loans;
        state.selectedLoanId = _.isEmpty(loans) ? null : loans[0].loanId;
    },
    [RECORDING_MUTATIONS.SET_LOOKUPS] (state, value) { state.lookups = value; },
    [RECORDING_MUTATIONS.SET_TOTAL_AMOUNT_VALUE] (state, value) { state.totalAmountValue = Number(value); },

    [RECORDING_MUTATIONS.SET_RECORDING_INFORMATION] (state, value) { state.recordingInformationItems = value; },
    [RECORDING_MUTATIONS.ADD_RECORDING_INFORMATION] (state, value) { state.recordingInformationItems.push(value); },
    [RECORDING_MUTATIONS.UPDATE_RECORDING_INFORMATION] (state, value) {
        let itemIndex = _.findIndex(state.recordingInformationItems, r => r.recordingInformationID === value.recordingInformationID);
        if(itemIndex < 0) return;
        state.recordingInformationItems.splice(itemIndex, 1, value);
    },
    [RECORDING_MUTATIONS.REMOVE_RECORDING_INFORMATION] (state, value) {
        let itemIndex = _.findIndex(state.recordingInformationItems, r => r.recordingInformationID === value);
        if(itemIndex < 0) return;
        state.recordingInformationItems.splice(itemIndex, 1);
    },

    [RECORDING_MUTATIONS.SET_TRANSFER_TAX_CALCULATIONS] (state, value) { state.transferTaxItems = value; },
    [RECORDING_MUTATIONS.ADD_TRANSFER_TAX_CALCULATION] (state, value) { state.transferTaxItems.push(value); },
    [RECORDING_MUTATIONS.UPDATE_TRANSFER_TAX_CALCULATION] (state, value) {
        let itemIndex = _.findIndex(state.transferTaxItems, t => t.manualTransferTaxCalculationID === value.manualTransferTaxCalculationID);
        if(itemIndex < 0) return;
        state.transferTaxItems.splice(itemIndex, 1, value);
    },
    [RECORDING_MUTATIONS.REMOVE_TRANSFER_TAX_CALCULATION] (state, value) {
        let itemIndex = _.findIndex(state.transferTaxItems, t => t.manualTransferTaxCalculationID === value);
        if(itemIndex < 0) return;
        state.transferTaxItems.splice(itemIndex, 1);
    },

    [RECORDING_MUTATIONS.SET_RECORDING_FEE_CALCULATIONS] (state, value) { state.recordingFeeItems = value; },
    [RECORDING_MUTATIONS.ADD_RECORDING_FEE_CALCULATION] (state, value) { state.recordingFeeItems.push(value); },
    [RECORDING_MUTATIONS.UPDATE_RECORDING_FEE_CALCULATION] (state, value) {
        let itemIndex = _.findIndex(state.recordingFeeItems, r => r.manualRecordingFeeCalculationID === value.manualRecordingFeeCalculationID);
        if(itemIndex < 0) return;
        state.recordingFeeItems.splice(itemIndex, 1, value);
    },
    [RECORDING_MUTATIONS.REMOVE_RECORDING_FEE_CALCULATION] (state, value) {
        let itemIndex = _.findIndex(state.recordingFeeItems, r => r.manualRecordingFeeCalculationID === value);
        if(itemIndex < 0) return;
        state.recordingFeeItems.splice(itemIndex, 1);
    }
};

export default { state, getters, actions, mutations };
