<template>
    <dx-select-box
        ref="selectBox"
        :input-attr="$utils.idAttrs(id)"
        :data-source="selectBoxDataSource"
        display-expr="name"
        :placeholder="placeholder"
        :show-data-before-search="true"
        :show-clear-button="true"
        :max-length="50"
        :disabled="disabled"
        no-data-text="No document template names found matching the entered value."
        v-model="selectedItem"
    />
</template>

<script setup>
    import { ref, watch, watchEffect } from "vue";
    import { useVModel } from "@vueuse/core";
    import DocumentTemplatesApi from "@/api/documentTemplates";
    import { SearchRequest } from "@/shared/models/models";

    const props = defineProps({
        id: { type: String, default: "" },
        placeholder: { type: String, default: "Select..." },
        documentName: { type: String, default: null },
        modelValue: { type: Number, default: null },
        disabled: { type: Boolean, default: false }
    });

    const emit = defineEmits([
        "update:modelValue",
        "update:documentName"
    ]);

    const selectedItem = ref(null);
    const selectBoxDataSource = ref({
        paginate: true,
        pageSize: 25,
        load: fetchData,
        byKey: () => selectedItem.value
    });

    const selectedValue = useVModel(props, "modelValue", emit);
    const selectedName = useVModel(props, "documentName", emit);

    watchEffect(() => {
        selectedItem.value = {
            documentTemplateID: props.modelValue,
            name: props.documentName
        };
    })

    watch(selectedItem, (newValue, oldValue) => {
        if(newValue === oldValue) return;
        selectedValue.value = newValue?.documentTemplateID || null;
        selectedName.value = newValue?.name || null;
    });

    async function fetchData(loadOptions) {
        var request = SearchRequest.fromLoadOptions(loadOptions);
        request.sortBy.push({ field: "name", direction: "ASC" });
        request.addFilter("regID", "=", 0);

        //loading indicator displayed by select-box, so $rqBusy is not needed here
        let {results, totalRecords} = await DocumentTemplatesApi.search(request);
        return { data: results, totalCount: totalRecords };
    }
</script>